<template>
<!--  <div id="login">-->
<!--    <div class="container">-->
      <b-row>
        <div class="col-sm-12 col-lg-6 offset-lg-3">
          <b-form-group
              id="fieldset-1"
              label="Введите пароль"
              label-for="input-1"
              valid-feedback=""
              :invalid-feedback="error.message"
              :state="!error.isError"
          >
            <b-form-input id="input-1" v-model="password" :state="!error.isError" trim></b-form-input>
          </b-form-group>
          <b-button block @click.prevent="auth()">Войти</b-button>
        </div>
      </b-row>
<!--    </div>-->
<!-- -->
<!--  </div>-->
</template>

<style lang="scss">
</style>

<script>
export default {
  name: "login-page",
  data: () => ({
    password: "",
    error: { isError: null, message: "" }
  }),
  async mounted() {
    this.password = this.$cookies.get("dildo-pass");

  },
  methods: {
    async auth() {
      fetch(`http://${process.env.VUE_APP_API_SERVER}:${process.env.VUE_APP_API_PORT}/auth/login`, {
        method: "POST",
        mode: "cors",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({ password: this.password }) // body data type must match "Content-Type" header
      })
      .then(resp => resp.json())
      .then(resp => {
        if (resp.err) {
          this.error = { isError: true, message: resp.err };
        } else {
          this.$cookies.set("dildo-token", resp.token)
          this.$cookies.set("dildo-pass", this.password)
          this.error = { isError: false, message: "" };

          if (resp.role != "admin") {
            this.error = {
              isError: true,
              message: "Ваша учетная запись не подтверждена, дождитесь проверки"
            };
          } else {
            this.$router.push({ path: "/" });
          }
        }
      })
      .catch(err => {
        this.error = { isError: true, message: err };
      })

    }
  },
  computed: {
  },
};
</script>